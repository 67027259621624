<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.14478 18.4819L1.17115 19.008L1.02933 18.6695C0.686222 17.846 0.430033 16.986 0.25619 16.1122C0.219591 15.943 0.25619 15.7646 0.356836 15.6182C0.452907 15.4809 0.599301 15.3894 0.763994 15.362L3.18407 14.9457H3.25269C3.54091 14.9457 3.79252 15.1424 3.852 15.426C4.00296 16.19 4.24543 16.9311 4.57482 17.6356C4.65716 17.8049 4.65259 18.0062 4.56109 18.1708C4.47417 18.3355 4.31863 18.4499 4.14021 18.4819H4.14478ZM23.3361 4.25919C18.2444 -1.17097 9.71234 -1.44545 4.28203 3.64619C4.06701 3.84747 3.86115 4.05333 3.65985 4.26834V0.864771L0 0.901369V10.8834H10.0234L9.98225 7.19615L5.92896 7.22817C9.3921 3.0469 15.591 2.46591 19.7724 5.92896C23.9537 9.392 24.5347 15.5907 21.0716 19.772C19.4155 21.7711 17.0366 23.0337 14.4518 23.2853C14.2689 23.3036 14.0904 23.3174 13.9074 23.3219L13.4866 23.3402L13.3585 27L13.8434 26.9863C13.9944 26.9863 14.1453 26.9771 14.2963 26.968C20.7605 26.5929 26.5614 20.8242 26.9685 14.3647C27.2247 10.6363 25.9072 6.97656 23.3407 4.25919H23.3361ZM10.5358 22.8736C9.07644 22.4116 7.74974 21.6156 6.65178 20.5497C6.54199 20.4445 6.39559 20.3804 6.24462 20.3804C6.1394 20.3804 6.03418 20.4079 5.94726 20.4673L3.7056 21.8992L3.49516 22.0502L3.47229 22.2698C3.45856 22.4207 3.50888 22.5671 3.60953 22.6815C5.0323 24.2094 6.78903 25.3851 8.74247 26.1217C8.82025 26.1491 8.90259 26.1674 8.98494 26.1674C9.23655 26.1674 9.46987 26.0302 9.59339 25.8106L10.8377 23.6559C10.9887 23.3997 10.9018 23.0658 10.6456 22.9148C10.609 22.8965 10.5724 22.8782 10.5358 22.8645V22.8736Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
